<template>
  <div id="everything">
    <div id="header">
    <div class="left"><h1><router-link to="/repository/PubFig">PubFig: Public Figures Face Database</router-link></h1></div><br>
    <div class="right">
    <h2><router-link to="/repository/PubFig/explore" class="unselected">Explore</router-link></h2>
    <h2><router-link to="/repository/PubFig/download" class="unselected">Download</router-link></h2>
    <h2><router-link to="/repository/PubFig/results" class="unselected">Results</router-link></h2>
    </div>
</div>

<div id="results">
    <div class="intro">
        <h2>Overview</h2>
        This page lists current results on the PubFig benchmark. To add your results, send the following information to <a href="mailto:neeraj@cs.columbia.edu">Neeraj Kumar</a>:
        <ul>
            <li>ROC curves in a text file. See the raw data section for the format of this file.</li>
            <li>Average score, computed as the average accuracy (<span class="code"># correct pairs / # total pairs</span>) using a fixed threshold (set beforehand) over all 10 folds of the cross-validation set</li>
            <li>Full citation for your paper</li>
            <li>Optionally: bibtex entry, pdf of paper (or link), webpage link</li>
        </ul>
    </div>
    <div class="resultpane" id="pubfigfull">
        <h2>Results</h2>
        <div class="tablediv">
            <table class="resultstable">
                <tbody><tr><th class="first">Algorithm</th><th>Average Accuracy</th></tr>
                <tr><td class="first">Attribute Classifiers (Kumar et al. 2009)</td><td>78.65%</td></tr>
            </tbody></table>
        </div>
        <div class="rocdiv"><img class="roc" src="https://cave.cs.columbia.edu/old/databases/pubfig/results/full_roc.png"></div>
    </div>
    <div id="data">
        <h2>Raw Data</h2>
        The raw datafile from which this graph was generated is <a href="https://cave.cs.columbia.edu/old/databases/pubfig/results/rocvalsfull.txt">here</a>. The file contains a heading for each curve to plot, on a line like:
        <pre class="code"># Attribute Classifiers (78.65%)</pre>
        where the <span class="code">#</span> signifies that it's a comment, and the rest of the line is used as the label for the plot curve. Each subsequent line has one point of the curve, as true positive rate followed by false positive rate, separated by a space or tab. Each point should be the average true positive/false positive rate over all 10 folds of the relevant cross-validation set, for a particular threshold value.
        <br><br>
        The graph was generated using <a href="https://cave.cs.columbia.edu/old/databases/pubfig/makePlots.py">this python script</a>. The script takes the raw datafile and output image name as arguments and generates the graph exactly as above. It's written in <a href="http://www.python.org/">Python</a> and requires <a href="http://matplotlib.sourceforge.net/">matplotlib</a>.
    </div>
    <div class="references">
        <h2>References</h2>
        <blockquote id="kumar_iccv_09">
    <div class="txtref">
        <b>"Attribute and Simile Classifiers for Face Verification,"</b><br>
        Neeraj Kumar, Alexander C. Berg, Peter N. Belhumeur, and Shree K. Nayar,<br>
        <i>International Conference on Computer Vision (ICCV), 2009.</i><br>
    </div>
    [<a href="http://cave.cs.columbia.edu/old/publications/pdfs/Kumar_ICCV09.pdf">pdf</a>]
    [<a href="https://cave.cs.columbia.edu/projects/categories/project?cid=Visual+Recognition&pid=Attribute+and+Simile+Classifiers+for+Face+Verification" target="_blank">webpage</a>]
</blockquote>

    </div>
</div>

<div id="footer">
    (c) 2010 Columbia University | Contact <a href="mailto:neeraj@cs.columbia.edu">Neeraj Kumar</a> for any questions/problems/bug reports/etc. | Current PubFig version: <b>1.2</b>
</div>
<br>
</div>
</template>

<script>
export default {

}
</script>
<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
#everything {
    font-family: Arial, sans-serif;
    padding: 10px;
    line-height: 1.3em;
    margin: 0 auto;
    width: 1000px;
    margin-top: 10px;
    text-align: left;
}

.teaser {
    display: block;
    margin: 0 auto;
}

a {
    color: blue;
    text-decoration: none;
}

#header {
    position: relative;
    display: block;
    height: 2.2em;
    /* border-bottom: 1px solid lightgray; */
    margin-bottom: 10px;
}

#header h2 {
    margin-top: 5px;
    display: inline-block;
    margin-left: 15px;
}

#header a {
    color: black;
}

#header a:hover {
    color: blue;
}

a:hover {
    text-decoration: underline;
}

#header .left {
    position: absolute;
    left: 0;
}

#header .left h2 {
    margin-left: 0;
}

#header .right {
    position: absolute;
    right: 0;
}

#header .selected {
    color: #ff872b;
}

h1 {
    font-size: 2.0em;
    margin-bottom: 10px;
}

h1.sectiontitle {
    text-align: center;
    margin: 20px;
}

h2 {
    font-size: 1.2em;
    margin: 20px 0 10px 0;
}

p {
    margin-bottom: 1em;
}

li {
    list-style: square outside;
    margin-left: 1em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

ol li {
    list-style-type: decimal;
    margin-left: 1.5em;
}

li li {
    list-style: circle outside;
}



div#content {
    clear: both;
    width: 100%;
    background: #f1ebda;
    padding-top: 10px;
    padding-bottom: 3em;
}

table caption {
    font-size: 2em;
}

h3 {
    margin-top: 5px;
    text-align: center;
}


div.resultpane {
    margin: 0 auto;
    margin-bottom: 30px;
}

div.rocdiv {
    margin-top: 2em;
    text-align: center;
}
div.tablediv {
    position: relative;
    text-align: center;
    margin-bottom: 1em;
}
table.resultstable {
    margin: 0 auto;
    border: 1px solid black;
}
table.resultstable th {
    border-bottom: 1px dotted black;
    padding: 5px;
    text-align: center;
}

table.resultstable td {
    padding: 5px;
    text-align: center;
}

.first{
    padding-right: 5px;
    border-right: 1px dotted black;
}

.exploretable {
    margin: 0 auto;
    text-align: center;
}

.exploreperson {
    display: inline-block;
    width: 186px;
    height: 130px;
    margin-bottom: 1em;
    margin-left: 5px;
    margin-right: 5px;
}

.exploreperson img {
    width: 170px;
    height: 85px;
    padding: 2px;
    border: 1px solid lightgray;
}

.exploreperson a:hover img {
    border-color: blue;
}

blockquote {
    margin-left: 2em;
    margin-top: 0.5em;
}

.fakelink {
    cursor: pointer;
}

.bibref {
    margin-top: 10px;
    margin-left: 10px;
    display:none;
    font-family: monospace;
}


.code {
    font-family: monospace;
    font-size: 1.2em;
}

pre {
    text-align: left;
    padding: 5px;
    background: lightgray;
    margin: 5px 0;
}

#footer {
    clear: both;
    text-align: center;
    border-top: 1px solid lightgray;
    padding-top: 5px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 0.9em;
}

</style>